<template>
    <FormRDStation id="wpp-formulario-orcamento-dropshipping-583d3e77c513ace5a5f4" code="UA-174539066-1"></FormRDStation>
</template>

<script>
import FormRDStation from '@/components/FormRDStation';
export default {
    components: {
        FormRDStation
    }
}
</script>