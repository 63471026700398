<template>
    <FormRDStation id="wpp-formulario-orcamento-ecommerce-d822d19af83ee875a583" code="UA-174539066-1"></FormRDStation>
</template>

<script>
import FormRDStation from '@/components/FormRDStation';
export default {
    components: {
        FormRDStation
    }
}
</script>