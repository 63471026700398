<template>
    <div>
        <div class="gmz-whatsapp-modal p-4">
            <div class="gmz-whatsapp-modal-close" @click="fecharModal">X</div>

            <div class="gmz-whatsapp-modal-content">
                
                <MainScreen v-if="tela == 'inicio'" @mudarTela="(t) => tela = t"></MainScreen>

                <TelaLojaFisica v-if="tela == 'lojafisica' "></TelaLojaFisica>

                <TelaEcommerce v-if="tela == 'ecommerce' "></TelaEcommerce>

                <TelaDropshipping v-if="tela == 'dropshipping' "></TelaDropshipping>

                <TelaOutros v-if="tela == 'outros' "></TelaOutros>
                
                <button class="btn btn-outline-success mt-1 w-100" v-if="tela != 'inicio'" @click="() => tela = 'inicio'">
                    Voltar
                </button>

            </div>

        </div>
    </div>
</template>

<script>
import MainScreen from './MainScreen.vue';
import TelaLojaFisica from './TelaLojaFisica.vue';
import TelaEcommerce from './TelaEcommerce.vue';
import TelaDropshipping from './TelaDropshipping.vue';
import TelaOutros from './TelaOutros.vue';

export default {
    components: {
        MainScreen,
        TelaLojaFisica,
        TelaEcommerce,
        TelaDropshipping,
        TelaOutros
    },
    data() {
        return {
            tela: 'inicio'
        }
    },
    methods: {
        irParaTela(tela) {
            this.tela = tela;
        },
        fecharModal() {
            this.$emit('fecharModal')
        }
    }
}
</script>

<style scoped>
.gmz-whatsapp-modal {    
    background-color: #EBE5DE;
    position: fixed;
    right: var(--gmz-whatsapp-modal-right, 25px);
    left: var(--gmz-whatsapp-modal-right, auto);
    bottom: var(--gmz-whatsapp-modal-bottom, 100px);
    width: 100%;
    height: auto;
    max-width: var(--gmz-whatsapp-modal-width, 350px);
    max-height: var(--gmz-whatsapp-modal-height, auto);
    box-shadow: var(--gmz-whatsapp-modal-box-shadow, 0 0 5px  rgba(0,0,0,0.1));
    border-radius: var(--gmz-whatsapp-modal-border-radius, 2px);
    transform: translate(-10px, -10px);
    display: block;
    border: 1pox ;
}

.gmz-whatsapp-modal-close {
    position: absolute;
    right: 2px;
    top: 2px;
    border: none;
    background: transparent;
    font-weight: 700;
    font-size: 20px;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #04AA6D;
    z-index: 999999;
    font-family: sans-serif;
}
</style>