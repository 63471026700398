<template>
    <FormRDStation id="wpp-formulario-orcamento-outros-b7c6f50113738de2c3b7" code="UA-174539066-1"></FormRDStation>
</template>

<script>
import FormRDStation from '@/components/FormRDStation';
export default {
    components: {
        FormRDStation
    }
}
</script>