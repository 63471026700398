<template>
    <div>
       
        <h2 class="h5 mb-4 text-center">Qual é o seu modelo de negócio?</h2>
        <div class="buttons">
            
            <button class="btn btn-success w-100 mb-2" @click="exibirTela('lojafisica')">
                Loja Física
            </button>

            <button class="btn btn-success w-100 mb-2" @click="exibirTela('ecommerce')">
                E-commerce
            </button>

            <button class="btn btn-success w-100 mb-2" @click="exibirTela('dropshipping')">
                Dropshipping
            </button>

            <button class="btn btn-success w-100 mb-2" @click="exibirTela('outros')">
                Outros
            </button>

        </div>

    </div>
</template>

<script>
export default {
    methods: {
        exibirTela(tela) {
            this.$emit('mudarTela', tela);
        }
    }
}
</script>